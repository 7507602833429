<template>
  <div class="header-container">
    <div class="search-warpper">
      <el-form :inline="true" :model="query">
        <el-form-item label="公告标题：">
          <el-input
            @change="$emit('search')"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入标题"
            v-model="query.titleLike"
          ></el-input>
        </el-form-item>
        <el-form-item label="公告分类：">
          <el-select
            @change="$emit('search')"
            size="small"
            clearable
            placeholder="请选择分类"
            style="width: 240px"
            v-model="query.categoryId"
          >
            <el-option v-for="item in noticeCate" :key="item.id" :label="item.categoryName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态：">
          <el-select
            size="small"
            @change="$emit('search')"
            style="width: 240px"
            v-model="query.status"
            clearable
            placeholder="请选择状态"
          >
            <el-option v-for="item in noticeStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit('search')"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { noticeCategoryList } from '@/api/helpcenter/helpcenter'
import { REQUEST_ALL_DATA } from '@/utils/constant'
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  async created() {
    try {
      const { code, detail } = await noticeCategoryList({
       ...REQUEST_ALL_DATA
      })
      if ($SUC({ code })) {
        this.noticeCate = detail
      } else {
        this.$message.error('获取公告分类失败')
      }
    } catch (e) {
      this.$message.error('获取公告分类失败')
    }
  },
  data() {
    return {
      // 公告分类筛选
      noticeCate: [],
      // 公告状态筛选
      noticeStatus: [
        {
          value: 4,
          label: '已发布'
        },
        {
          value: 3,
          label: '定时发布'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  .search-warpper {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #1a1a1a;
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
  }
}
</style>
