var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"anouncementList"},[_c('myHeader',{attrs:{"query":_vm.query},on:{"search":_vm.searchChange}}),_c('div',{staticClass:"buttonArr"},[_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:noticeList:createNotice']),expression:"['externaladmin:noticeList:createNotice']"}],attrs:{"type":"primary","size":"small"},on:{"click":_vm.createNotice}},[_vm._v("+ 创建公告")]),_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:noticeList:batchRevoke']),expression:"['externaladmin:noticeList:batchRevoke']"}],staticClass:"blueBoder",attrs:{"loading":_vm.revokeLoading,"size":"small"},on:{"click":_vm.batchRevoke}},[_vm._v("批量撤销")]),_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:noticeList:batchDelete']),expression:"['externaladmin:noticeList:batchDelete']"}],staticClass:"blueBoder",attrs:{"loading":_vm.deleteLoading,"size":"small"},on:{"click":_vm.batchDelete}},[_vm._v("批量删除")])],1),_c('CommonTable',{attrs:{"tableLoading":_vm.tableLoading,"height":"auto","cols":_vm.cols,"infoData":_vm.data},on:{"selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"templateName",fn:function(ref){
var row = ref.scoped;
return [_c('div',{staticClass:"templateName",on:{"click":function($event){return _vm.showNotice(row)}}},[_vm._v(_vm._s(row.title))])]}},{key:"releaseTimeSlot",fn:function(ref){
var row = ref.scoped;
return [_c('div',[_c('span',[_vm._v("开始时间：")]),_c('span',[_vm._v(_vm._s(row.releaseTime))])]),_c('div',[_c('span',[_vm._v("结束时间：")]),_c('span',[_vm._v(_vm._s(row.endTime))])])]}},{key:"releaseType",fn:function(ref){
var row = ref.scoped;
return [_c('div',[_vm._v(_vm._s(row.releaseType === 1?'即时公告':'定时公告'))])]}},{key:"status",fn:function(ref){
var row = ref.scoped;
return [_c('div',[_vm._v(_vm._s(_vm.getStatus(row.status)))])]}}])},[_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"350px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Popover',{on:{"sureHandler":function($event){return _vm.revoke(row)}},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('p',[_vm._v("确定撤销?")])]},proxy:true},{key:"reference",fn:function(ref){
var loading = ref.scope;
return [_c('color-text-btn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:noticeList:revoke']),expression:"['externaladmin:noticeList:revoke']"}],staticClass:"update-btn",attrs:{"loading":loading,"size":"mini"}},[_vm._v("撤销")])]}}],null,true)}),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('Popover',{on:{"sureHandler":function($event){return _vm.onDelete(row)}},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('p',[_vm._v("确定删除至回收站?")])]},proxy:true},{key:"reference",fn:function(ref){
var loading = ref.scope;
return [_c('color-text-btn',{staticClass:"update-btn",attrs:{"loading":loading,"size":"mini","type":"danger"}},[_vm._v("删除")])]}}],null,true)})]}}])})],1),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}}),_c('el-dialog',{staticClass:"articleWarpper",attrs:{"title":"查看详情","visible":_vm.noticeVisible,"width":"1000px","before-close":_vm.noticehandleClose}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.articleLoading),expression:"articleLoading"}],staticClass:"articleContent"},[_c('div',{staticClass:"articleContent_title",domProps:{"innerHTML":_vm._s(_vm.articleData.title)}}),_c('div',{staticClass:"articleContent_line2"},[_c('div',{staticClass:"articleContent_line2_type"},[_vm._v("公告分类:"),_c('span',{domProps:{"innerHTML":_vm._s(_vm.articleData.categoryName)}})]),_c('div',{staticClass:"articleContent_line2_time"},[_vm._v("时间:"),_c('span',{domProps:{"innerHTML":_vm._s(_vm.articleData.releaseTime)}})])]),_c('div',{staticClass:"articleContent_content",domProps:{"innerHTML":_vm._s(_vm.articleData.content)}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }